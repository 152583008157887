<!--
 * @Description: 首页组件
 * @Author: hai-27
 * @Date: 2020-02-07 16:23:00
 * @LastEditors: hai-27
 * @LastEditTime: 2020-02-27 13:36:12
 -->
<template>
  <div class="home" id="home" name="home">
    <!-- 轮播图 -->
    <div class="block">
      <el-carousel height="350px">
        <el-carousel-item v-for="item in carousel" :key="item.carouselId">
          <div style="width: 100%;text-align: center;background-color: #FFFFFF">
            <img style="height:350px" :src="$target + item.imgPath" :alt="item.describes"/>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 轮播图END -->

    <div class="main-box">
      <div class="main">
        <!-- 手机商品展示区域 -->
        <div class="phone">
          <div class="box-hd">
            <div class="title">医疗保健</div>
          </div>
          <div class="box-bd">
            <!--            <div class="promo-list">-->
            <!--              <router-link to>-->
            <!--                <img-->
            <!--                    src="//cdn.cnbj1.fds.api.mi-img.com/mi-mall/9613bd4127fede053f8ba5049eff0392.jpeg?thumb=1&w=293&h=768&f=webp&q=90"/>-->
            <!--              </router-link>-->
            <!--            </div>-->
            <div class="list">
              <MyList :list="phoneList" :isMore="true"></MyList>
            </div>
          </div>
        </div>
        <!-- 手机商品展示区域END -->

        <!--        &lt;!&ndash; 电视商品展示区域 &ndash;&gt;-->
        <!--        <div class="phone">-->
        <!--          <div class="box-hd">-->
        <!--            <div class="title">电视影音</div>-->
        <!--          </div>-->
        <!--          <div class="box-bd">-->
        <!--            <div class="promo-list">-->
        <!--              <router-link to>-->
        <!--                <img :src="$target +'public/imgs/phone/phone.png'" />-->
        <!--              </router-link>-->
        <!--            </div>-->
        <!--            <div class="list">-->
        <!--              <MyList :list="miTvList" :isMore="true"></MyList>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
        <!--        &lt;!&ndash; 电视商品展示区域END &ndash;&gt;-->

        <!--        &lt;!&ndash; 配件商品展示区域 &ndash;&gt;-->
        <!--        <div class="accessory" id="promo-menu">-->
        <!--          <div class="box-hd">-->
        <!--            <div class="title">热门商品</div>-->
        <!--            <div class="more" id="more">-->
        <!--              <MyMenu :val="3" @fromChild="getChildMsg2">-->
        <!--                <span slot="1">热门</span>-->
        <!--                <span slot="2">保护套</span>-->
        <!--                <span slot="3">充电器</span>-->
        <!--              </MyMenu>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--          <div class="box-bd">-->
        <!--            <div class="promo-list">-->
        <!--              <ul>-->
        <!--                <li>-->
        <!--                  <img :src="$target +'public/imgs/accessory/accessory-promo1.png'" alt />-->
        <!--                </li>-->
        <!--                <li>-->
        <!--                  <img :src="$target +'public/imgs/accessory/accessory-promo2.png'" alt />-->
        <!--                </li>-->
        <!--              </ul>-->
        <!--            </div>-->
        <!--            <div class="list">-->
        <!--              <MyList :list="accessoryList" :isMore="true"></MyList>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
        <!--        &lt;!&ndash; 配件商品展示区域END &ndash;&gt;-->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      carousel: [{ imgPath: '/imgs/logo1.jpg' },
        { imgPath: '/imgs/logo2.jpg' }], // 轮播图数据
      phoneList: [
        {
          productID: 1,
          productName: '头部按摩仪',
          productTitle: '头部按摩器，揉捏按摩爪充电式头皮按摩仪头部放松，防水干湿两用放松头部理疗器。',
          productPicture: '/imgs/p1.jpg',
          productSellingPrice: '163.00',
          productPrice: '195.00',
          detailPicture: ['/imgs/d1.jpg', '/imgs/d2.jpg', '/imgs/d3.jpg', '/imgs/d4.jpg']
        },
        {
          productID: 2,
          productName: '智能语音揉捏按摩枕',
          productTitle: '按摩器按摩枕肩颈按摩仪颈椎枕全身颈部脖子小腿腰部多功能电动靠垫揉腹仪',
          productPicture: '/imgs/p2.jpg',
          productSellingPrice: '266.99',
          productPrice: '319.99',
          detailPicture: ['/imgs/d5.jpg', '/imgs/d6.jpg']
        },
        {
          productID: 2,
          productName: '全自动按摩足浴盆可折叠泡脚桶',
          productTitle: '全自动按摩足浴盆可折叠泡脚桶电动加热洗脚盆恒温泡脚盆足浴桶',
          productPicture: '/imgs/p3.jpg',
          productSellingPrice: '168.99',
          productPrice: '196.99',
          detailPicture: ['/imgs/d7.jpg', '/imgs/d8.jpg']
        },
      ], // 手机商品列表
      miTvList: '', // 小米电视商品列表
      // applianceList: "", // 家电商品列表
      // applianceHotList: "", //热门家电商品列表
      accessoryList: '', //配件商品列表
      accessoryHotList: '', //热门配件商品列表
      protectingShellList: '', // 保护套商品列表
      chargerList: '', //充电器商品列表
      applianceActive: 1, // 家电当前选中的商品分类
      accessoryActive: 1 // 配件当前选中的商品分类
    }
  },
  watch: {
    // // 家电当前选中的商品分类，响应不同的商品数据
    // applianceActive: function(val) {
    //   // 页面初始化的时候把applianceHotList(热门家电商品列表)直接赋值给applianceList(家电商品列表)
    //   // 所以在切换商品列表时判断applianceHotList是否为空,为空则是第一次切换,把applianceList赋值给applianceHotList
    //   if (this.applianceHotList == "") {
    //     this.applianceHotList = this.applianceList;
    //   }
    //   if (val == 1) {
    //     // 1为热门商品
    //     this.applianceList = this.applianceHotList;
    //     return;
    //   }
    //   if (val == 2) {
    //     // 2为电视商品
    //     this.applianceList = this.miTvList;
    //     return;
    //   }
    // },
    accessoryActive: function (val) {
      // 页面初始化的时候把accessoryHotList(热门配件商品列表)直接赋值给accessoryList(配件商品列表)
      // 所以在切换商品列表时判断accessoryHotList是否为空,为空则是第一次切换,把accessoryList赋值给accessoryHotList
      if (this.accessoryHotList == '') {
        this.accessoryHotList = this.accessoryList
      }
      if (val == 1) {
        // 1为热门商品
        this.accessoryList = this.accessoryHotList
        return
      }
      if (val == 2) {
        // 2为保护套商品
        this.accessoryList = this.protectingShellList
        return
      }
      if (val == 3) {
        //3 为充电器商品
        this.accessoryList = this.chargerList
        return
      }
    }
  },
  created () {
    // 获取轮播图数据
    // this.$axios
    //   .get("/api/resources/carousel", {})
    //   .then(res => {
    //     this.carousel = res.data.data;
    //   })
    //   .catch(err => {
    //     return Promise.reject(err);
    //   });
    // 获取各类商品数据
    this.getPromo(1, 'phoneList')
    this.getPromo(2, 'miTvList')
    this.getPromo(5, 'protectingShellList')
    this.getPromo(7, 'chargerList')
    // this.getHot(
    //   "applianceList"
    // );
    this.getHot(
        'accessoryList'
    )
  },
  methods: {
    // 获取家电模块子组件传过来的数据
    // getChildMsg(val) {
    //   this.applianceActive = val;
    // },
    // 获取配件模块子组件传过来的数据
    getChildMsg2 (val) {
      this.accessoryActive = val
    },
    // 获取各类商品数据方法封装
    getPromo (categoryId, val) {
      let api = '/api/product/category/limit/' + categoryId
      this.$axios
          .get(api)
          .then(res => {
            this[val] = res.data.data
          })
          .catch(err => {
            return Promise.reject(err)
          })
    },
    getHot (val) {
      let api = '/api/product/category/hot'
      this.$axios
          .get(api)
          .then(res => {
            this[val] = res.data.data
          })
          .catch(err => {
            return Promise.reject(err)
          })
    }
  }
}
</script>
<style scoped>
@import "../assets/css/index.css";
</style>